<template>
  <div
    id="compact-header"
    class="fixed -top-56 z-30 hidden w-screen items-center bg-white drop-shadow-sm md:flex md:flex-col"
  >
    <div
      class="mx-auto flex w-full items-center h-24 px-4 2xl:container justify-between"
    >
      <MenuButton />
      <a class="mx-3 xl:mx-5" href="/" aria-label="Ir a página principal">
        <LogoType />
      </a>
      <Searchbar class="grow" />
      <div class="mr-3 ml-2 lg:mx-3 xl:mx-6 min-w-max w-fit">
        <InformacionDespacho />
      </div>

      <LoginButton class="mr-3 lg:mr-3 xl:mr-6 min-w-max w-fit" />

      <CartButton />
    </div>
    <!-- <Huincha
      :configHuincha="configHuincha"
      v-if="configHuincha"
      class="w-full z-30 drop-shadow-sm md:flex relative hidden"
    /> -->
  </div>
</template>
<script setup lang="ts">
  import { onMounted } from "@vue/runtime-core";
  import MenuButton from "./MenuButton.vue";
  import LogoType from "@components/LogoType.vue";
  import Searchbar from "@components/Searchbar.vue";
  import LoginButton from "./LoginButton.vue";
  import CartButton from "./CartButton.vue";
  import type { Ventana as VentanaInterfaz } from "@lib/interfaces";
  import InformacionDespacho from "./InformacionDespacho.vue";
  import type { ConfigHuincha } from "@lib/interfaces";
  // import Huincha from "../Huincha.vue";

  interface Props {
    ventanaActiva: VentanaInterfaz | undefined;
    direccion?: string;
    configHuincha?: ConfigHuincha;
  }
  defineProps<Props>();

  const activationHeight = 40;
  const headerHeight = 148;
  onMounted(() => {
    const header = document.getElementById("compact-header") as HTMLElement;
    adjustHeader();
    window.addEventListener("scroll", () => {
      adjustHeader();
    });
    function adjustHeader() {
      if (window.scrollY > activationHeight) {
        if (headerHeight - (window.scrollY - activationHeight) < 0) {
          return (header.style.top = "0px");
        }
        return (header.style.top = `${-(
          headerHeight -
          (window.scrollY - activationHeight)
        )}px`);
      } else {
        return (header.style.top = "-200px");
      }
    }
  });
</script>
