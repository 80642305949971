<template>
  <svg
    :height="height"
    viewBox="0 21 54 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.0008 24.6669V27.6114H7.65219C7.77138 27.6114 7.87442 27.6953 7.89529 27.8109C7.96061 28.179 7.97543 28.547 7.97543 28.9157C7.97543 29.2838 7.96129 29.6518 7.89529 30.0206C7.87442 30.1362 7.77206 30.2201 7.65219 30.2201H3.0008V34.4789C3.0008 34.5991 2.91124 34.7002 2.79002 34.7181C2.357 34.7808 1.92399 34.8244 1.4903 34.8244C1.05728 34.8244 0.639084 34.7808 0.210783 34.7181C0.0902389 34.7002 0 34.5991 0 34.4789V22.2412C0 22.1077 0.110442 22 0.245802 22H8.23672C8.35592 22 8.45761 22.0833 8.47916 22.1982C8.54919 22.5716 8.57882 22.9548 8.57882 23.3235C8.57882 23.7114 8.54919 24.0848 8.47848 24.468C8.45693 24.583 8.35524 24.6676 8.23605 24.6676H3.0008V24.6669Z"
      :fill="fill"
    ></path>
    <path
      d="M20.4101 34.2601C20.4997 34.4048 20.4141 34.5912 20.2444 34.6229C19.6087 34.7418 18.7939 34.7834 18.0497 34.7834C17.8376 34.7834 17.612 34.7834 17.416 34.7729C17.3339 34.7682 17.2598 34.7233 17.2187 34.6539L14.626 30.3172H14.4852C14.0219 30.3172 13.5189 30.3172 13.0151 30.2974V34.4828C13.0151 34.6017 12.9276 34.7028 12.8077 34.722C12.3882 34.7887 11.9565 34.8033 11.5248 34.8033C11.0864 34.8033 10.6642 34.7881 10.2029 34.7193C10.083 34.7015 9.99414 34.5997 9.99414 34.4801V22.3945C9.99414 22.2782 10.0776 22.179 10.1941 22.1572C11.4333 21.9233 12.9882 21.7812 14.3445 21.7812C18.2511 21.7812 19.8626 23.3817 19.8626 25.8517C19.8626 27.6893 18.9561 28.954 17.5669 29.6657L20.4101 34.2601ZM13.0151 27.867C13.5586 27.8869 14.1027 27.8869 14.6058 27.8869C16.1769 27.8869 16.8012 27.0569 16.8012 26.0294C16.8012 24.9424 16.0759 24.2704 14.5856 24.2704C14.0421 24.2704 13.5384 24.2902 13.0145 24.3299V27.867H13.0151Z"
      :fill="fill"
    ></path>
    <path
      d="M30.6969 33.3621C30.6969 33.7309 30.668 34.1141 30.5973 34.4874C30.5757 34.6024 30.4733 34.6857 30.3548 34.6857H22.0622C21.9262 34.6857 21.8164 34.5773 21.8164 34.4445V22.2412C21.8164 22.1077 21.9268 22 22.0622 22H30.1629C30.2774 22 30.3777 22.0773 30.4026 22.1863C30.4861 22.5531 30.5158 22.9528 30.5158 23.3235C30.5158 23.6949 30.4861 24.0669 30.4026 24.4383C30.3777 24.5486 30.2774 24.6279 30.1616 24.6279H24.8165V26.9202H29.5494C29.6679 26.9202 29.7703 27.0034 29.7918 27.1184C29.8619 27.4871 29.8908 27.8558 29.8908 28.2246C29.8908 28.5933 29.8619 28.9475 29.7918 29.3115C29.7696 29.4259 29.6679 29.5091 29.5494 29.5091H24.8159V32.0584H30.3548C30.4733 32.0584 30.5757 32.1417 30.5973 32.2567C30.668 32.624 30.6969 32.9928 30.6969 33.3621Z"
      :fill="fill"
    ></path>
    <path
      d="M31.6312 33.3051C31.7551 32.4077 32.2178 31.6438 32.8515 31.1912C32.9377 31.1298 33.0555 31.1317 33.141 31.1945C33.9916 31.8163 35.2785 32.2954 36.7095 32.2954C38.1594 32.2954 38.7231 31.8408 38.7231 31.1489C38.7231 30.4967 38.4005 30.1214 36.125 29.5485C33.9498 29.0344 32.0366 28.2045 32.0164 25.6558C32.0164 23.2255 33.9902 21.6641 36.9708 21.6641C39.1029 21.6641 40.5568 22.311 41.3508 22.8852C41.4222 22.9367 41.4592 23.0226 41.4491 23.1092C41.3549 23.9253 40.9151 24.7942 40.2147 25.2699C40.1333 25.3255 40.0248 25.3261 39.942 25.2719C39.1985 24.7929 38.1184 24.3706 36.7695 24.3706C35.5815 24.3706 34.9371 24.8054 34.9371 25.3981C34.9371 26.0305 35.2192 26.3074 37.5352 26.8604C39.9521 27.4532 41.6034 28.4609 41.6438 30.7333C41.7044 33.4802 39.7508 35.0013 36.6496 35.0013C34.1559 35.0013 32.572 34.2182 31.7181 33.5264C31.6514 33.4742 31.6198 33.3896 31.6312 33.3051Z"
      :fill="fill"
    ></path>
    <path
      d="M52.6451 24.6286H49.1749V34.4855C49.1749 34.6037 49.0873 34.7048 48.9681 34.724C48.5338 34.7954 48.0994 34.8251 47.6644 34.8251C47.23 34.8251 46.8112 34.7954 46.3808 34.724C46.2616 34.7042 46.1741 34.6037 46.1741 34.4855V24.6286H42.6898C42.5706 24.6286 42.4689 24.5453 42.4473 24.4304C42.3773 24.0577 42.3477 23.6764 42.3477 23.3242C42.3477 22.939 42.3766 22.5676 42.4466 22.1982C42.4682 22.0839 42.5706 22 42.6891 22H52.6464C52.7622 22 52.8626 22.0786 52.8875 22.1896C52.9717 22.561 53.0006 22.933 53.0006 23.3044C53.0006 23.6777 52.971 24.0511 52.8862 24.437C52.8612 24.5486 52.7609 24.6286 52.6451 24.6286Z"
      :fill="fill"
    ></path>
  </svg>
</template>
<script setup lang="ts">
  defineProps({
    height: {
      type: String,
      default: "24px",
    },
    stroke: {
      type: String,
      default: "black",
    },
    fill: {
      type: String,
      default: "black",
    },
  });
</script>
